<template>
    <div class="card-slider grid grid-cols-12 component overflow-hidden">
        <div class="col-span-12">
            <div class="flex flex-col md:flex-row justify-between md:items-end mb-10 md:mb-16">
                <h2 class="headline-big max-md:mb-0">{{data.title}} <span class="font-serif">/</span> <span class="text-gray-dark">{{data.titleEnglish}}</span></h2>

                <NuxtLink :to="data.entryLink[0].uri" v-if="data.events.length <= 3 && data.entryLink[0]" class="shrink-0">
                    <ElementButton :title="data.linkText" :englishTitle="data.linkTextEnglish" class="shrink-0"/>
                </NuxtLink>

                <div class="shrink-0 flex flex-row gap-3 hidden md:block" v-if="data.events.length > 3">
                    <button @click="prevSlide" class="transition duration-300" :class="{'opacity-25 pointer-events-none': isBeginning}">
                        <svg class="rotate-180 inline-block ml-4 w-[2.2rem] shrink-0 group-hover:ml-5 transition-all duration-200" xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none" viewBox="0 0 35 35"><circle cx="17.5" cy="17.5" r="16.5" stroke="#000" stroke-width="2"/><path fill="#000" stroke="#000" stroke-width=".5" d="M17.835 24.727a.766.766 0 0 0 1.082 0l6.188-6.187a.766.766 0 0 0 0-1.082l-6.188-6.188-.006-.006a.766.766 0 0 0-1.082 1.082l.006.006 4.88 4.881H9.438a.766.766 0 0 0 0 1.532h13.277l-4.88 4.88a.766.766 0 0 0 0 1.082Z"/></svg>
                    </button>
                    <button @click="nextSlide" class="transition duration-300" :class="{'opacity-25 pointer-events-none': isEnd}">
                        <svg class="inline-block ml-4 w-[2.2rem] shrink-0 group-hover:ml-5 transition-all duration-200" xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none" viewBox="0 0 35 35"><circle cx="17.5" cy="17.5" r="16.5" stroke="#000" stroke-width="2"/><path fill="#000" stroke="#000" stroke-width=".5" d="M17.835 24.727a.766.766 0 0 0 1.082 0l6.188-6.187a.766.766 0 0 0 0-1.082l-6.188-6.188-.006-.006a.766.766 0 0 0-1.082 1.082l.006.006 4.88 4.881H9.438a.766.766 0 0 0 0 1.532h13.277l-4.88 4.88a.766.766 0 0 0 0 1.082Z"/></svg>
                    </button>
                </div>
            </div>

            <swiper
                ref="swiperRef"
                :slides-per-view="1"
                :modules="[Navigation]"
                :breakpoints="{
                    1: {
                        slidesPerView: 1,
                        spaceBetween: 15,
                    },
                    1024: {
                        slidesPerView: 3,
                        såaceBetween: 30,
                    },
                }"
                @slideChange="onSlideChange"
                :space-between="30"
            >
                <swiper-slide v-for="(event, index) in data.events" :key="index">
                    <ElementCard
                        :data="event"
                    />
                </swiper-slide>
            </swiper>
            <NuxtLink :to="data.entryLink[0].uri" class="mt-8 md:mt-20 block" v-if="data.entryLink[0] && data.events.length > 3">
                <ElementButton :title="data.linkText" :englishTitle="data.linkTextEnglish" class="shrink-0"/>
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

const props = defineProps({
    data: {
        type: Object,
        default: {
            title: 'Forestillinger',
            titleEnglish: 'Shows',
            events: [
                {
                    title: 'Event 1',
                    titleEnglish: 'Event 1',
                    dateFrom: '2024-06-04T07:00:00+00:00',
                    dateTo: '2024-06-04T07:00:00+00:00',
                    stage: 'Scene 1',
                    media: {
                        url: 'https://sydhavnteater:8890/assets/Screenshot-2024-07-01-at-14.12.29.jpg',
                        width: 891,
                        height: 551,
                        mimeType: 'image/jpeg',
                        title: 'Hero image'
                    }
                },
                {
                    title: 'Event 2',
                    titleEnglish: 'Event 2',
                    dateFrom: '2024-06-04T07:00:00+00:00',
                    dateTo: '2024-06-04T07:00:00+00:00',
                    stage: 'Scene 2',
                    media: {
                        url: 'https://sydhavnteater:8890/assets/Screenshot-2024-07-01-at-14.12.29.jpg',
                        width: 891,
                        height: 551,
                        mimeType: 'image/jpeg',
                        title: 'Hero image'
                    }
                },
                {
                    title: 'Event 3',
                    titleEnglish: 'Event 3',
                    dateFrom: '2024-06-04T07:00:00+00:00',
                    dateTo: '2024-06-04T07:00:00+00:00',
                    stage: 'Scene 3',
                    media: {
                        url: 'https://sydhavnteater:8890/assets/Screenshot-2024-07-01-at-14.12.29.jpg',
                        width: 891,
                        height: 551,
                        mimeType: 'image/jpeg',
                        title: 'Hero image'
                    }
                }
            ]
        }
    }
})

const swiperRef = ref(null)
const isBeginning = ref(true)
const isEnd = ref(false)

const onSwiper = (instance) => {
    swiperRef.value = instance;
}
const onSlideChange = () => {

    isBeginning.value = swiperRef.value.$el.swiper.isBeginning;
    isEnd.value = swiperRef.value.$el.swiper.isEnd;
}

const nextSlide = () => {
    swiperRef.value.$el.swiper.slideNext()
}

const prevSlide = () => {
    swiperRef.value.$el.swiper.slidePrev()
}
</script>

<style lang="scss" scoped>
.swiper {
    overflow: visible;
    @apply max-md:w-11/12 m-0;
}
</style>